import "./AssociatedRequestCard.css";

import { AnalysisRequestTypeEnum, RequestTypeTranslationTerms } from "../../../../requests/models/domain/AnalysisRequestTypeEnum";
import { useCallback, useMemo } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../../lib/infrastructure/ui/UIServices";

import { AssReqCardSampleAR } from "./associated-request-types/AssReqCardSampleAR";
import { AssReqCardSampleDF } from "./associated-request-types/AssReqCardSampleDF";
import { AssReqCardSampleDRYER } from "./associated-request-types/AssReqCardSampleDRYER";
import { AssReqCardSamplePP } from "./associated-request-types/AssReqCardSamplePP";
import { AssReqCardSampleWF } from "./associated-request-types/AssReqCardSampleWF";
import { CardContainer } from "../../../../../lib/layouts/containers/card/CardContainer";
import { ColumnDefinition } from "../../../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { CorrelatedRequestSampleSummary } from "../../../models/domain/CorrelatedRequestSampleSummary";
import { DateTimeHelper } from "../../../../../lib/utils/DateTimeHelper";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { LabSampleService } from "../../../services/LabSampleService";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { ReactComponent as RejecttIcon } from "../../../../../lib/assets/icons/reject.svg";
import { ReactComponent as RequestIcon } from "../../../../../lib/assets/icons/pedidos.svg";
import { RequestSummary } from "../../../models/domain/RequestSummary";
import { ResponsiveDataTable } from "../../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../../lib/components/buttons/ScalableIconButton";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { Tag } from "../../../../../lib/components/tag/Tag";
import { TextButton } from "../../../../../lib/components/buttons/TextButton";
import { Tooltip } from "../../../../../lib/components/tooltip/Tooltip";
import { WarningPopup } from "../../../../../lib/components/popup/WarningPopup";
import { requestStatusCss } from "../../../../../common/helpers/RequestStatusCss";
import { requestStatusLabel } from "../../../../../common/helpers/RequestStatusLabel";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useNavigate } from "react-router-dom";
import { useServiceCallPro2 } from "../../../../../services/UseServiceCall";

var labSampleSvc = new LabSampleService();

interface IAssociatedRequestCardProps {
    sampleID: string;
    locationID: string;
    data: CorrelatedRequestSampleSummary | undefined;
    onCompletedOperations: () => void;
    hasTopPermissions: boolean; 
    isLoadingTopPermissions: boolean; 
}

export function AssociatedRequestCard(props: IAssociatedRequestCardProps) {
    const navigate = useNavigate();
    const windowResize = useWindowResize();
    const createNotification = useCreateNotification();

    const openPopup = usePopup();
    const closePopup = useClosePopup();


    const [removeCorrelationCall, removeCorrelationCallIsLoading] = useServiceCallPro2(labSampleSvc, labSampleSvc.removeLabSampleCorrelation);


    const handleRemoveCorrelationClicked = useCallback(() => {

        if (!props.sampleID || !props.locationID || !props.data?.requestSummary?.analysisRequestId) return;

        removeCorrelationCall(props.locationID, props.sampleID, { analysisRequestId: props.data.requestSummary.analysisRequestId })
            .then((_) => {
                props.onCompletedOperations();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate(
                            "SAMPLES.INFOANDFORM.RemoveCorrelationSuccessMessage"
                        )}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });


    }, [props.sampleID, props.locationID, props.data?.requestSummary?.analysisRequestId, removeCorrelationCall,
    props.onCompletedOperations, createNotification, openPopup]);




    const requestSummaryAsArray: RequestSummary[] = [];

    if (props.data?.requestSummary)
        requestSummaryAsArray.push(props.data.requestSummary);


    const columns: ColumnDefinition<RequestSummary>[] = useMemo(
        () => [
            {
                cellRenderProp: (v: RequestSummary) => v.reference,
                headerRender: translate("REQUESTS.INFOANDFORM.Reference"),
                columnKey: "ref",
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v: RequestSummary) => v.name,
                headerRender: translate("REQUESTS.INFOANDFORM.Name"),
                columnKey: "name",
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v: RequestSummary) => translate(RequestTypeTranslationTerms[v.type ? v.type : -1]),
                headerRender: translate("REQUESTS.INFOANDFORM.Type"),
                columnKey: "type",
            },
            {
                cellRenderProp: (v: RequestSummary) => v.waybill,
                headerRender: translate("REQUESTS.INFOANDFORM.WaybillAbrev"),
                columnKey: "numSamples",
            },
            {
                cellRenderProp: (v: RequestSummary) => v.createdBy,
                headerRender: translate("REQUESTS.INFOANDFORM.Requester"),
                columnKey: "requester",
            },
            {
                cellRenderProp: (v: RequestSummary) => v.createdDate ? DateTimeHelper.formatDateTime(`${v.createdDate}`) : null,
                headerRender: translate("REQUESTS.INFOANDFORM.CreationDate"),
                columnKey: "creationDate",
            },
            {
                cellRenderProp: (v: RequestSummary) => (
                    <Tag
                        text={requestStatusLabel(v.status ? v.status : -1)}
                        backgroundColor={requestStatusCss(v.status ? v.status : -1)}
                        isTiny
                    />
                ),
                headerRender: translate("REQUESTS.INFOANDFORM.Status"),
                columnKey: "status",
                isStatusCell: true
            },
            {
                cellRenderProp: (v: RequestSummary) => (<Tooltip
                    text={translate("SAMPLES.INFOANDFORM.AssociatedRequest")}>
                    <ScalableIconButton size={24} icon={<RequestIcon />} onClick={() => { navigate(`/requests/view/` + v.analysisRequestId) }} />
                </Tooltip>),
                headerRender: <></>,
                columnKey: "open-correlated-request",
                width: "1.0714rem",
                isMobileHeaderIcon: true
            },
        ],
        [navigate, windowResize]
    );



    const renderRequestSampleInfo = useMemo(() => {

        if (!props.data?.info?.sampleType)
            return <></>;
        return (
            <div className="request-sample">
                <div className="subtitle">
                    {translate("SAMPLES.INFOANDFORM.RequestSampleData")}
                </div>
                {(() => {
                    switch (props.data?.info?.sampleType) {
                        case AnalysisRequestTypeEnum.AR:
                            return <AssReqCardSampleAR sampleInfo={props.data.info} />;
                        case AnalysisRequestTypeEnum.DRYER:
                            return <AssReqCardSampleDRYER data={props.data.info} />;
                        case AnalysisRequestTypeEnum.PP:
                            return <AssReqCardSamplePP data={props.data.info} />;
                        case AnalysisRequestTypeEnum.WF:
                            return <AssReqCardSampleWF data={props.data.info} />;
                        case AnalysisRequestTypeEnum.DF:
                            return <AssReqCardSampleDF data={props.data.info} />;
                        default:
                            return <></>;
                    }
                })()}
            </div>
        );

    }, [props.data?.info]);

    return <CardContainer className="request-card">
        <div className="request-card-container">
            <div className="subtitle">
                {translate("SAMPLES.INFOANDFORM.AssociatedRequest")}
            </div>
            {removeCorrelationCallIsLoading ? <FullScreenLoader /> : null}
            <TextButton
                className="small-copy"
                text={windowResize > 425 ? translate("SAMPLES.INFOANDFORM.RemoveCorrelation") : translate("SAMPLES.INFOANDFORM.RemoveCorrelationAbrev")}
                type="red"
                icon={<RejecttIcon />}
                isIconBefore
                isDisabled={props.isLoadingTopPermissions || !props.hasTopPermissions}
                onClick={() =>
                    openPopup(
                        <WarningPopup
                            onDismissClicked={() => { closePopup(); }}
                            onConfirmClicked={handleRemoveCorrelationClicked}
                            dismissText={translate("COMMON.Cancel")}
                            confirmText={translate("COMMON.Remove")}
                            className="button-red"
                        >
                            {translate("SAMPLES.INFOANDFORM.RemoveCorrelationConfirmText")}
                        </WarningPopup>
                    )}
            />
        </div>
        <Spacer mode="vertical" px={12} />
        <div className="request-info">
            <ResponsiveDataTable
                items={requestSummaryAsArray || []}
                columnDefinitions={columns}
                totalitems={0}
                onClickRow={() => { }}
            />
            <ConditionalRender if={true}>
                {renderRequestSampleInfo}
            </ConditionalRender>
        </div>
    </CardContainer>;
}