import "./AssociateSampleTestsPopupCopy.css";

import { TestSelector, TestSelectorCategory, TestSelectorTest } from "./TestSelector";
import { useClosePopup, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";
import { useCallback, useEffect, useMemo, useState } from "react";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { LabTest } from "../../../../../models/domain/lab-tests/LabTest";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { RequestSamplesService } from "../../../services/RequestSamplesService";
import { RequestSampleSummaryDTO } from "../../../models/api/RequestSampleSummaryDTO";
import { CardContainer } from "../../../../../lib/layouts/containers/card/CardContainer";
import { AssociationTestsSampleService } from "../../../services/AssociationTestsSampleService";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { SampleTestAssociation } from "../../../models/domain/SampleTestAssociation";


var requestSamplesSvc = new RequestSamplesService();
var associateTestsSampleSvc = new AssociationTestsSampleService();


interface IAssociateSampleTestsPopup {
  locationId: string | undefined;
  requestId: string | undefined;
  labTests: LabTest[];
  sampleId: string;
  sampleTestsAssociations: SampleTestAssociation[] | undefined;
  className?: string;
  onClickButton?: () => void;
  onTableRowClick?: () => void;
  onCompletedOperations?: () => void;

}

export function AssociateSampleTestsPopupCopy(props: IAssociateSampleTestsPopup) {
  const openPopup = usePopup();
  const closePopup = useClosePopup();


  const [isLoadingSampleInfo, setIsLoadingSampleInfo] = useState<boolean>(true);

  const [selectedTestIds, setSelectedTestIds] = useState<number[]>([]); //Target Tests ID's.
  const [selectedSample, setSelectedSample] = useState<RequestSampleSummaryDTO>();
  const [isFullScreenLoaderDisabled, setIsFullScreenLoaderDisabled] = useState<boolean>(true);



  //ID's dos testes já selecionados. 
  useEffect(() => {

    setSelectedTestIds(props.sampleTestsAssociations?.map((a) => a.sampleTestId) ?? []);

  }, [props.sampleTestsAssociations]);




  useEffect(() => {
    if (!props.locationId || !props.requestId || !props.sampleId) return;

    setIsLoadingSampleInfo(true);

    requestSamplesSvc.getRequestSampleById(props.locationId, props.requestId, props.sampleId)
      .then((sample) => {
        setSelectedSample(sample);
        setIsLoadingSampleInfo(false);
      }).catch(error => {
        setIsLoadingSampleInfo(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });

  }, [props.locationId, props.requestId, props.sampleId]);



  const testSelectorDefinition = useMemo(() => {


    const testsGroupedByTypeName = props.labTests.reduce((group: { [key: string]: LabTest[] }, item) => {
      if (!group[item.labTestTypeDescription]) {
        group[item.labTestTypeDescription] = [];
      }
      group[item.labTestTypeDescription].push(item);
      return group;
    }, {});

    return Object.keys(testsGroupedByTypeName).map((cat): TestSelectorCategory => {
      return ({
        label: cat,
        children: testsGroupedByTypeName[cat].map((t): TestSelectorTest => {
          return ({
            id: t.labTestId,
            name: t.labTestName,
            additionalInfo: t.isForDestroyingBarOrFilter ? "(" + translate("REQUESTS.INFOANDFORM_2.DestroysSample") + ")" : null,
            isUsed: t.isUsed,
            cost: t.cost ?? 0,
            usedCost: t.usedCost ?? 0,
            isDisabled: t.isDisabled ?? false
          })
        }).filter((t) => selectedSample?.isUsed ? t.isUsed && (t.usedCost != null && t.usedCost > 0) : !t.isUsed && (t.cost != null && t.cost > 0))
          .filter((t) => {
            if (!t.isDisabled || props.sampleTestsAssociations?.find((a) => a.sampleTestId === t.id))
              return t;
          })
      })
    }).filter((g) => g.children.length > 0);
  }, [props.labTests, selectedSample?.isUsed, props.sampleTestsAssociations]);





  const handleAssociateBtnClicked = useCallback(() => {

    if (!props.locationId || !props.requestId || !props.sampleId) return;
    setIsFullScreenLoaderDisabled(false);


    var filteredTestsWithNonNullCosts = selectedTestIds?.map((testID) => {

      var labTestMatch = props.labTests.find((b) => b.labTestId === testID);

      if (selectedSample?.isUsed && labTestMatch?.usedCost != null && labTestMatch.usedCost > 0) {
        return testID;
      }
      if (!selectedSample?.isUsed && labTestMatch?.cost != null && labTestMatch.cost > 0)
        return testID;
      return undefined;
    }).filter((b) => b) as number[];


    associateTestsSampleSvc
      .editSampleTestsCorrelation(props.locationId, props.requestId, { sampleId: props.sampleId, labTestsIds: filteredTestsWithNonNullCosts })
      .then((_) => {
        setIsFullScreenLoaderDisabled(true);
        props.onCompletedOperations && props.onCompletedOperations();
        closePopup();
      })
      .catch(error => {
        setIsFullScreenLoaderDisabled(true);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });

  }, [props.locationId, props.requestId, props.sampleId, selectedTestIds, selectedSample?.isUsed,
    setIsFullScreenLoaderDisabled, props.onCompletedOperations, closePopup, openPopup]);




  return (
    <PopupContainer className="popup-associate-tests-sample">
      <PopupHeader title={translate("REQUESTS.POPUPS.AssociateTestsWithSample")} />
      {isFullScreenLoaderDisabled ? null : <FullScreenLoader />}
      <PopupContent isLoading={isLoadingSampleInfo}>

        <CardContainer className="associate-tests-sample">
          <div className="header">
            {translate("REQUESTS.POPUPS.SelectedSample")}
          </div>
          <div className="sample-info">
            {selectedSample?.material.number && selectedSample?.material.name ?
              (selectedSample?.material.number + " - " + selectedSample?.material.name)
              : (selectedSample?.material.name ? selectedSample.material.name : "-")}
          </div>
        </CardContainer>
        <Spacer mode="vertical" px={25} />

        <div className="filtered-by-warning">{selectedSample?.isUsed ?
          translate("GLOBALADMIN.TESTS.INFO.FilteredByUsed") : translate("GLOBALADMIN.TESTS.INFO.FilteredByNonUsed")}</div>

        <Spacer mode="vertical" px={12} />
        <TestSelector
          definition={testSelectorDefinition}
          selectedTestIds={selectedTestIds}
          onSelectedTestIdsChanged={setSelectedTestIds} />

        <Spacer mode={"vertical"} px="20"></Spacer>

        <div className="selected-tests-info-container">
          <div className="total-selected">
            {translate("REQUESTS.POPUPS.NoSelectedTests") + ": " + selectedTestIds.length}
          </div>
        </div>

        <Spacer mode={"vertical"} px="10"></Spacer>

        <PopupActionButtons
          buttons={[
            {
              text: translate("COMMON.Cancel"),
              type: "tertiary",
              onClick: () => closePopup(),
            },
            {
              text: translate("REQUESTS.POPUPS.Associate"),
              type: "primary",
              onClick: handleAssociateBtnClicked,
            },
          ]}
        />
      </PopupContent>
    </PopupContainer>
  );
}