import "./AddSamples.css";

import { useCallback, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { AddInfo } from "./AddInfo";
import { AddSampleARPopup } from "./popup/create-request-samples/AddSampleARPopup";
import { AddSampleDFPopup } from "./popup/create-request-samples/AddSampleDFPopup";
import { AddSampleDryerPopup } from "./popup/create-request-samples/AddSampleDryerPopup";
import { AddSamplePPPopup } from "./popup/create-request-samples/AddSamplePPPopup";
import { AddSampleWFPopup } from "./popup/create-request-samples/AddSampleWFPopup";
import { AnalysisRequestStatusEnum } from "../../models/domain/AnalysisRequestStatusEnum";
import { AnalysisRequestTypeEnum } from "../../models/domain/AnalysisRequestTypeEnum";
import { BetweenNumbersHelper } from "../../../../lib/helpers/BetweenNumbersHelper";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { ReactComponent as CorrelateIcon } from "../../../../lib/assets/icons/correlacionar.svg";
import { CorrelateSamplesPopup } from "./popup/CorrelateSamplesPopup";
import { DateTimeHelper } from "../../../../lib/utils/DateTimeHelper";
import { ReactComponent as DeleteIcon } from "../../../../lib/assets/icons/remover.svg";
import { ReactComponent as EditIcon } from "../../../../lib/assets/icons/editar.svg";
import { EditRequestSampleDetailsPopup } from "./popup/EditRequestSampleDetailsPopup";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { IButtonProps } from "../../../../lib/components/buttons/Button";
import { IResponsiveDataTableProps } from "../../../../lib/components/table/ResponsiveDataTable";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { Permission } from "../../../../models/api/gate/Permission";
import { ReactComponent as RemoveCorrelationIcon } from "../../../../lib/assets/icons/reject-round.svg";
import { RequestDetails } from "../../models/domain/RequestDetails";
import { RequestSampleDetailsPopup } from "./popup/view-request-sample-details/RequestSampleDetailsPopup";
import { RequestSampleSummaryDTO } from "../../models/api/RequestSampleSummaryDTO";
import { RequestSamplesService } from "../../services/RequestSamplesService";
import { ReactComponent as CloneIcon } from "../../../../lib/assets/icons/clone.svg";
import { SampleTypeTranslationTerms } from "../../../samples/models/domain/SampleTypeEnum";

import { ReactComponent as AddSampleIcon } from "../../../../lib/assets/icons/add-sample_v2.svg";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { Tag } from "../../../../lib/components/tag/Tag";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { getSampleStatusCss } from "../../../../common/helpers/GetSampleStatusCss";
import { getSampleStatusLabel } from "../../../../common/helpers/GetSampleStatusLabel";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useIdentityUserDisplayName } from "../../../../lib/infrastructure/identity/hooks/useIdentityUserDisplayName";

var samplesSvc = new RequestSamplesService();

interface IAddSamplesProps {
  info: RequestSampleSummaryDTO[];
  className?: string;
  onCompletedOperations?: () => void;
  onClickButton?: () => void;
  onTableRowClick?: () => void;
  requestId: string | undefined;
  requestDetails: RequestDetails | undefined;
  locationID: string | undefined;
  isLoading: boolean;
}

const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.LOCATION_ANALISTS];

export function AddSamples(props: IAddSamplesProps) {
  const useIdentity = useIdentityUserDisplayName();
  const createNotification = useCreateNotification();
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const windowResize = useWindowResize();

  const { isLoadingPermissions, hasPermission } = useHasPermissions(permissions);
  const [isFullScreenLoaderActive, setIsFullScreenLoaderActive] = useState<boolean>(false);


  const deleteRequestSamples = useCallback((sampleId: number) => {

    if (!props.locationID || !props.requestId) return;
    setIsFullScreenLoaderActive(true);

    samplesSvc.deleteRequestSample(props.locationID, props.requestId, sampleId)
      .then(() => {
        setIsFullScreenLoaderActive(false);
        props.onCompletedOperations && props.onCompletedOperations();
      }).catch((error) => {

        setIsFullScreenLoaderActive(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      })
  }, [props.locationID, props.requestId, openPopup, props.onCompletedOperations, setIsFullScreenLoaderActive]);




  const renderAddSamplePopup = useCallback((cloneSampleID?: string | undefined) => {

    if (!props.requestId || !props.requestDetails?.requestType || !props.locationID) return;

    switch (props.requestDetails?.requestType) {
      case AnalysisRequestTypeEnum.AR:
        return openPopup(<AddSampleARPopup cloneSampleID={cloneSampleID} locationID={props.locationID} requestID={props.requestId} onCompletedOperations={() => props.onCompletedOperations && props.onCompletedOperations()} />);
      case AnalysisRequestTypeEnum.WF:
        return openPopup(<AddSampleWFPopup cloneSampleID={cloneSampleID} locationID={props.locationID} requestID={props.requestId} onCompletedOperations={() => props.onCompletedOperations && props.onCompletedOperations()} />);
      case AnalysisRequestTypeEnum.PP:
        return openPopup(<AddSamplePPPopup cloneSampleID={cloneSampleID} locationID={props.locationID} requestID={props.requestId} onCompletedOperations={() => props.onCompletedOperations && props.onCompletedOperations()} />);
      case AnalysisRequestTypeEnum.DF:
        return openPopup(<AddSampleDFPopup cloneSampleID={cloneSampleID} locationID={props.locationID} requestID={props.requestId} onCompletedOperations={() => props.onCompletedOperations && props.onCompletedOperations()} />);
      case AnalysisRequestTypeEnum.DRYER:
        return openPopup(<AddSampleDryerPopup cloneSampleID={cloneSampleID} locationID={props.locationID} requestID={props.requestId} onCompletedOperations={() => props.onCompletedOperations && props.onCompletedOperations()} />);
      default:
        return;
    }
  }, [props.requestId, props.requestDetails?.requestStatus, props.onTableRowClick, props.onCompletedOperations, props.locationID, openPopup]);



  const canManageSamples = useMemo(() => {

    if (isLoadingPermissions || !props.requestDetails?.requestStatus) return false;

    var authorHasPermission = props.requestDetails?.createdBy?.email?.toLowerCase() === useIdentity.userDisplayEmail?.toLowerCase()
      && (props.requestDetails?.requestStatus === AnalysisRequestStatusEnum.DRAFT || props.requestDetails?.requestStatus === AnalysisRequestStatusEnum.REGISTERED)

    var analystHasPermissions = hasPermission &&
      (props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.CANCELED && props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.DONE);

    return (authorHasPermission || analystHasPermissions)

  }, [isLoadingPermissions, props.requestDetails?.requestStatus, props.requestDetails?.createdBy?.email, useIdentity.userDisplayEmail,
    hasPermission]);




  const canAddSamples = useMemo(() => {

    if (isLoadingPermissions || !props.requestDetails?.requestStatus) return false;

    var authorHasPermission = props.requestDetails?.createdBy?.email?.toLowerCase() === useIdentity.userDisplayEmail?.toLowerCase()
      && (props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.CANCELED && props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.DONE);

    var analystHasPermissions = hasPermission &&
      (props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.CANCELED && props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.DONE);

    return (authorHasPermission || analystHasPermissions)

  }, [isLoadingPermissions, props.requestDetails?.requestStatus, props.requestDetails?.createdBy?.email, useIdentity.userDisplayEmail, hasPermission]);



  const hasAnalystPermssions = useMemo(() => {

    if (isLoadingPermissions || !props.requestDetails?.requestStatus) return false;

    return hasPermission && (props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.CANCELED && props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.DONE);

  }, [isLoadingPermissions, props.requestDetails?.requestStatus, hasPermission]);



  const isRequestAuthor = useMemo(() => {
    return props.requestDetails?.createdBy?.email?.toLowerCase() === useIdentity.userDisplayEmail?.toLowerCase()
      && (props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.CANCELED && props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.DONE);

  }, [props.requestDetails?.createdBy?.email, useIdentity.userDisplayEmail, props.requestDetails?.requestStatus]);




  const removeSampleCorrelation = useCallback((sampleId: string) => {

    if (!props.requestId || !props.locationID) return;
    setIsFullScreenLoaderActive(true);

    samplesSvc.deleteRequestSampleCorrelation(props.locationID, props.requestId, sampleId)
      .then((_) => {
        setIsFullScreenLoaderActive(false);
        props.onCompletedOperations && props.onCompletedOperations();
        createNotification(<Notification
          type="success"
          title={translate("COMMON.SYSTEMPOPUPS.Success")}
          text={translate("REQUESTS.POPUPS.RemoveCorrelationSuccess")} />);
      }).catch(error => {
        setIsFullScreenLoaderActive(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [props.locationID, props.requestId, setIsFullScreenLoaderActive, props.onCompletedOperations, openPopup, createNotification]);








  const columns: ColumnDefinition<RequestSampleSummaryDTO>[] = useMemo(() => [
    {
      cellRenderProp: (v) => {
        return (
          <div className="material-container">
            <div className="code">{v.material.number ? v.material.number : v.material.name}</div>
            <div className="small-copy material-description">{v.material.number ? v.material.name : null}</div>
          </div>)

      },
      headerRender: <>{translate("SAMPLES.INFOANDFORM.Material")}</>,
      columnKey: "sample",
      isMobilePrimaryCell: true,
      width: "15%",
    },
    {
      cellRenderProp: (v) => v.manufacturerIsValmet ? "Valmet" : v.otherManufacturer,
      headerRender: translate("SAMPLES.INFOANDFORM.Manufacturer"),
      columnKey: "manufacturer",
    },
    {
      cellRenderProp: (v) => v.isUsed ? translate("COMMON.Yes") : translate("COMMON.No"),
      headerRender: translate("SAMPLES.INFOANDFORM.Used"),
      columnKey: "used",
      width: "60px",
    },
    {
      cellRenderProp: (v) => v.labSampleCorrelated?.labSampleType ? translate(SampleTypeTranslationTerms[v.labSampleCorrelated.labSampleType]) : null,
      headerRender: translate("SAMPLES.INFOANDFORM.Type"),
      columnKey: "type",
      isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1024),
    },
    {
      cellRenderProp: (v) => v.labSampleCorrelated?.observations,
      headerRender: translate("SAMPLES.INFOANDFORM.Observations"),
      columnKey: "Observations",
      isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1024),
      width: "20%",
    },
    {
      cellRenderProp: (v) => v.labSampleCorrelated?.requestDate ? DateTimeHelper.formatDateTime("" + v.labSampleCorrelated?.requestDate) : null,
      headerRender: translate("SAMPLES.INFOANDFORM.RequestDateSummary"),
      columnKey: "RD",
      isSortable: true,
      width: "110px",
      isMobilePrimaryCell: true,
    },
    {
      cellRenderProp: (v) => v.labSampleCorrelated?.labSampleStatus ? (
        <Tag
          text={getSampleStatusLabel(v.labSampleCorrelated?.labSampleStatus)}
          backgroundColor={getSampleStatusCss(v.labSampleCorrelated?.labSampleStatus)}
          isTiny
        />) : null,
      headerRender: translate("SAMPLES.INFOANDFORM.Status"),
      columnKey: "sample-status",
      isStatusCell: true,
    },
    {
      cellRenderProp: (v) => !v.correlatedLabSampleId ?
        (<Tooltip text={translate("REQUESTS.POPUPS.CorrelateSample")}>
          <ScalableIconButton size={24} isDisabled={hasAnalystPermssions ? false : true} icon={<CorrelateIcon />}
            onClick={() =>
              openPopup(<CorrelateSamplesPopup
                locationId={props.locationID}
                requestId={props.requestId}
                sampleId={`${v.id}`}
                waybill={props.requestDetails?.waybill ? props.requestDetails.waybill : undefined}
                materialReference={v.material}
                onCompletedOperations={() => props.onCompletedOperations && props.onCompletedOperations()}
              />)}
          />
        </Tooltip>) :
        (<Tooltip text={translate("REQUESTS.POPUPS.RemoveCorrelation")}>
          <ScalableIconButton size={24} isDisabled={hasAnalystPermssions ? false : true} icon={<RemoveCorrelationIcon />}
            onClick={() => {
              openPopup(
                <WarningPopup
                  onDismissClicked={() => { closePopup(); }}
                  onConfirmClicked={() => { removeSampleCorrelation("" + v.id) }}
                  dismissText={translate("COMMON.Cancel")}
                  confirmText={translate("REQUESTS.POPUPS.RemoveCorrelation")}
                  className="button-red"
                >
                  {translate("REQUESTS.POPUPS.RemoveCorrelationConfirmText", v.material.name)}
                </WarningPopup>
              );
            }} /></Tooltip>),
      columnKey: "remove-correlation",
      width: "24px",
      isMobileHeaderIcon: true
    },
    {
      cellRenderProp: (v) =>
      (<Tooltip text={translate("REQUESTS.POPUPS.EditSample")}>

        <ScalableIconButton
          size={24}
          isDisabled={isRequestAuthor && !v.correlatedLabSampleId ? false : hasAnalystPermssions ? false : true}
          /*isDisabled={canManageSamples && props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.DONE
                      && props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.CANCELED ? false : true} */

          icon={<EditIcon />}
          onClick={() => {
            openPopup(<EditRequestSampleDetailsPopup
              locationId={props.locationID}
              requestId={props.requestId}
              sampleId={"" + v.id}
              onOperationsCompleted={() => props.onCompletedOperations && props.onCompletedOperations()}
            />
            );
          }
          }
        /></Tooltip>),
      columnKey: "delete-sample",
      width: "24px",
      isMobileHeaderIcon: true,
    },
    {
      cellRenderProp: (v) => <div className="action-icons">
        <Tooltip text={translate("COMMON.CloneRequestSample")}>
          <ScalableIconButton
            className="clone-btn"
            isDisabled={(isRequestAuthor || hasAnalystPermssions) ? false : true}
            size={24}
            icon={<CloneIcon />}
            onClick={() => { renderAddSamplePopup(`${v.id}`) }}
          />
        </Tooltip>
      </div>,
      columnKey: "action-icons",
      width: "4.4286rem",
      isVisible: windowResize > 768,
    },
    {
      cellRenderProp: (v) =>
      (<Tooltip text={translate("REQUESTS.POPUPS.RemoveSample")}>
        <ScalableIconButton size={24}
          /*         isDisabled={canManageSamples ? false : true}  */
          isDisabled={isRequestAuthor && !v.correlatedLabSampleId ? false : hasAnalystPermssions ? false : true}
          icon={<DeleteIcon />}
          onClick={() => {
            openPopup(
              <WarningPopup
                onDismissClicked={() => { closePopup(); }}
                onConfirmClicked={() => { deleteRequestSamples(v.id) }}
                dismissText={translate("COMMON.Cancel")}
                confirmText={translate("REQUESTS.POPUPS.RemoveSample")}
                className="button-red"
              >
                {translate("REQUESTS.POPUPS.RemoveSampleConfirmText", v.material.name)}
              </WarningPopup>
            );
          }
          }
        /></Tooltip>),
      columnKey: "delete-sample",
      width: "24px",
      isMobileHeaderIcon: true,
    },
  ], [props.locationID, props.requestId, props.requestDetails?.waybill, closePopup, isRequestAuthor,
    hasAnalystPermssions, canManageSamples, isLoadingPermissions, openPopup, deleteRequestSamples,
    removeSampleCorrelation, props.onCompletedOperations, windowResize]);



  const onClickRenderLabSampleDetails = useCallback((row: RequestSampleSummaryDTO) => {
    if (row.correlatedLabSampleId != null) {
      window.open(`/samples/view/` + row.correlatedLabSampleId, '_blank');
    } else {
      return openPopup(<RequestSampleDetailsPopup
        locationID={props.locationID}
        requestID={props.requestId}
        sampleID={"" + row.id} />)
    }
  }, [props.locationID, props.requestId, openPopup]);



  const samplesTableInfo: IResponsiveDataTableProps<RequestSampleSummaryDTO> = useMemo(() => {
    return {
      items: props.info,
      columnDefinitions: columns,
      isLoading: props.isLoading,
      totalitems: 0,
      onClickRow: onClickRenderLabSampleDetails
    };
  }, [props.info, props.isLoading, columns, onClickRenderLabSampleDetails]);



  const addSamplesButton: IButtonProps = useMemo(() => {
    return {
      text: translate("REQUESTS.ACTIONS.AddSample"),
      type: "secondary",
      icon: <AddSampleIcon />,
      isDisabled: canAddSamples ? false : true,
      onClick: () => renderAddSamplePopup(undefined)
    };
  }, [renderAddSamplePopup, canAddSamples]);



  return (
    <>
      {isFullScreenLoaderActive ? <FullScreenLoader /> : null}
      <AddInfo
        title={translate("SAMPLES.Samples")}
        button={addSamplesButton}
        tableInfo={samplesTableInfo}
        className="add-info-samples"
        isLoading={props.isLoading}
      />
    </>

  );
}
