import "./CorrelateLabSamplesPopup.css";
import { ReactComponent as ArrowDown } from "../../../../lib/assets/icons/arrowDown.svg";
import { ReactComponent as ArrowUp } from "../../../../lib/assets/icons/arrowUp.svg";
import { ReactComponent as CleanIcon } from "../../../../lib/assets/icons/clean.svg";
import { ReactComponent as CorrelateIcon } from "../../../../lib/assets/icons/correlacionar.svg"
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { ReactComponent as SearchIcon } from "../../../../lib/assets/icons/search-icon.svg";
import { useFormControl } from "../../../../lib/components/form/Form";
import { SampleType } from "../../models/domain/SampleType";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SampleTypeAsEnum, SampleTypeTranslationTerms } from "../../models/domain/SampleTypeEnum";
import { Sample } from "../../models/domain/Sample";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { buildMaterialCellinfo } from "../../../../common/helpers/BuildMaterialCellinfo";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { BetweenNumbersHelper } from "../../../../lib/helpers/BetweenNumbersHelper";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { Tag } from "../../../../lib/components/tag/Tag";
import { getSampleStatusLabel } from "../../../../common/helpers/GetSampleStatusLabel";
import { getSampleStatusCss } from "../../../../common/helpers/GetSampleStatusCss";
import { DateTimeHelper } from "../../../../lib/utils/DateTimeHelper";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { TitledCardContainer } from "../../../../lib/layouts/containers/card/TitledCardContainer";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormFieldSelectSingle } from "../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { AnalysisRequestType } from "../../../requests/models/domain/AnalysisRequestType";
import { LabSampleService } from "../../services/LabSampleService";
import { SampleAllData } from "../../models/domain/SampleAllData";
import { useServiceCallPro2 } from "../../../../services/UseServiceCall";
import { GetAnalysisRequestType, RequestTypeAsEnum } from "../../../requests/models/domain/AnalysisRequestTypeEnum";
import { AnalysisRequestSamplesToCorrelateFilterRequestDTO } from "../../models/api/AnalysisRequestSamplesToCorrelateFilterRequestDTO";
import { trimString } from "../../../../lib/utils/TrimString";
import axios from "axios";
import { PossibleLabSampleCorrelation } from "../../models/domain/PossibleLabSampleCorrelation";
import { CorrelateLabSampleRequestDTO } from "../../models/api/CorrelateLabSampleRequestDTO";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { useNavigate } from "react-router-dom";

var labSampleSvc = new LabSampleService();

const labelRequestTypeSelector = (item: AnalysisRequestType) => item.description;
const idRequestTypeSelector = (item: AnalysisRequestType) => item.id;

interface ICorrelateLabSamplesPopup {
    locationID: string | undefined;
    labSampleID: string | undefined;
    batch: string | undefined;
    waybill: string | undefined;
    materialReference: string | undefined;
    navigateToViewSamples?: boolean;
    onCompletedOperations?: () => void;
}



export interface CorrelateLabSampleFilters {
    requestNumber?: string;
    requestType: AnalysisRequestType | undefined;
    sampleType: SampleType | undefined;
    searchWord: string | undefined;
    waybill?: string;
    material?: string;
}

interface SelectedRequestSample {
    requestID: number;
    requestSampleID: number;
}



export function CorrelateLabSamplesPopup(props: ICorrelateLabSamplesPopup) {
    const navigate = useNavigate();
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();
    const windowResize = useWindowResize();
    const upperRowRef = useRef<HTMLDivElement>(null);
    const bottomRowRef = useRef<HTMLDivElement>(null);

    const [possibleSamplesToCorrelate, setPossibleSamplesToCorrelate] = useState<PossibleLabSampleCorrelation[]>();
    const [totalItems, setTotalItems] = useState<number>(0);


    const [currentpage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(4);

    const [isFiltersPanelExpanded, setIsFiltersPanelExpanded] = useState<boolean>(false);
    const [isFiltersWrapped, setIsFiltersWrapped] = useState<boolean>(false);

    const [labSampleData, setLabSampleData] = useState<SampleAllData>();


    const [getLabSampleCall, getLabSampleCallIsLoading] = useServiceCallPro2(labSampleSvc, labSampleSvc.getLabSampleById);
    const [getPossibleCorrelationsCall, getPossibleCorrelationsCallIsLoading] = useServiceCallPro2(labSampleSvc, labSampleSvc.getPossibleSampleCorrelations);
    const [correlateLabSampleCall, correlateLabSampleCallIsLoading] = useServiceCallPro2(labSampleSvc, labSampleSvc.correlateLabSamplePage);


    const [selectedRequestSample, setSelectedRequestSample] = useState<SelectedRequestSample>();

    const [tableRenderId, setTableRenderID] = useState<number>(1);


    const requestTypes = useMemo(() => {
        return GetAnalysisRequestType()
    }, []);


    var requestReferenceFormControl = useFormControl<string>({});

    const requestTypeFormControl = useFormControl<AnalysisRequestType>({ isDisabled: false });

    var waybillControl = useFormControl<string>({ initialValue: props.waybill });

    var materialReferenceControl = useFormControl<string>({ initialValue: props.materialReference })

    var batchFormControl = useFormControl<string>({ initialValue: props.batch });




    useEffect(() => {
        getSamplaData();
    }, []);



    const getSamplaData = useCallback(() => {
        if (!props.locationID || !props.labSampleID) return;
        getLabSampleCall(props.locationID, props.labSampleID)
            .then((res) => {
                setLabSampleData(res);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [getLabSampleCall, props.locationID, props.labSampleID, setLabSampleData, openPopup]);





    useEffect(() => {

        setSelectedRequestSample(undefined);
        setTableRenderID(tableRenderId + 1);


        const controller = new AbortController();
        getPossibleCorrelations(controller);



        return () => { controller.abort() };

    }, [currentpage, itemsPerPage, requestTypeFormControl.value, batchFormControl.value, waybillControl.value,
        requestReferenceFormControl.value, materialReferenceControl.value])





    const getPossibleCorrelations = useCallback((controller: AbortController) => {

        if (!props.locationID || !props.labSampleID) return;

        var requestDto: AnalysisRequestSamplesToCorrelateFilterRequestDTO = {
            page: currentpage,
            pageLength: itemsPerPage,
            analysisRequestType: requestTypeFormControl.value?.id ? RequestTypeAsEnum[parseInt(requestTypeFormControl.value?.id)] : undefined,
            batch: trimString(batchFormControl.value),
            waybill: trimString(waybillControl.value),
            analysisRequestReference: trimString(requestReferenceFormControl.value),
            material: trimString(materialReferenceControl.value),
            filterWithoutCorrelation: false
            /*  analysisRequestId: pq crl o DTO tem esta merda? */
        };


        getPossibleCorrelationsCall(props.locationID, props.labSampleID, requestDto, controller.signal)
            .then((res) => {
                setPossibleSamplesToCorrelate(res.samples);
                setTotalItems(res.totalItems ?? 0);

            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [props.locationID, props.labSampleID, currentpage, itemsPerPage,
    requestTypeFormControl.value, batchFormControl.value, waybillControl.value, requestReferenceFormControl.value, materialReferenceControl.value,
        getPossibleCorrelationsCall, setPossibleSamplesToCorrelate, setTotalItems, openPopup]);





    const handleCorrelateSampleBtnClicked = useCallback(() => {

        if (!props.locationID || !props.labSampleID || !selectedRequestSample?.requestID || !selectedRequestSample.requestSampleID) return;

        var requestDto: CorrelateLabSampleRequestDTO = {
            analyisRequestId: selectedRequestSample?.requestID || -1,
            analysisRequestSampleId: selectedRequestSample?.requestSampleID || -1
        };


        correlateLabSampleCall(props.locationID, props.labSampleID, requestDto)
            .then((_) => {
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("REQUESTS.INFOANDFORM_2.CorrelatedSample")}
                    ></Notification>
                );
                props.onCompletedOperations && props.onCompletedOperations();
                closePopup();
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [selectedRequestSample?.requestID, selectedRequestSample?.requestSampleID,
        createNotification, props.locationID, props.labSampleID, correlateLabSampleCall, props.onCompletedOperations, openPopup, closePopup]);




    const columns: ColumnDefinition<PossibleLabSampleCorrelation>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.reference,
                headerRender: translate("REQUESTS.INFOANDFORM.Reference"),
                isSortable: true,
                columnKey: "request-reference",

            },
            {
                cellRenderProp: (v) => v.waybill,
                headerRender: translate("SAMPLES.INFOANDFORM.Waybill"),
                columnKey: "waybill",
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v: PossibleLabSampleCorrelation) => v.sample.material?.number ?
                    `${v.sample.material.number} - ${v.sample.material.name}`
                    : (v.sample.material?.name ? v.sample.material?.name : v.sample.otherMaterial),
                headerRender: translate("SAMPLES.INFOANDFORM.Material"),
                columnKey: "MAT",
                isSortable: true,
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => v.sample.batchNumber,
                headerRender: translate("SAMPLES.INFOANDFORM.Batch"),
                isSortable: true,
                columnKey: "BTC",
            },
            {
                cellRenderProp: (v) => v.sample.isUsed ? translate("COMMON.Yes") : translate("COMMON.No"),
                headerRender: translate("REQUESTS.Used"),
                columnKey: "Observations",
                isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1024),
            },
            {
                cellRenderProp: (v) => {
                    return (<>{v.sample.correlatedLabSampleId
                        ? (<Tag
                            text={translate("REQUESTS.INFOANDFORM_2.CorrelatedSample")}
                            backgroundColor="status-green"
                            isTiny={windowResize < 1024}
                            icon={<CorrelateIcon />
                            }
                        />
                        ) : null}</>)
                },
                columnKey: "correlated-sample",
                isMobilePrimaryCell: true,
            },
        ],
        [windowResize]
    );



    const handleOnPageAndItemsChanged = useCallback((items: number, currentPage: number) => {
        setItemsPerPage(items);
        setCurrentPage(currentPage);
    }, [setItemsPerPage, setCurrentPage]);



    const handleResetFiltersClicked = useCallback(() => {
        batchFormControl.setValue("");
        waybillControl.setValue("")
        materialReferenceControl.setValue("");
        requestReferenceFormControl.setValue("");
        requestTypeFormControl.setValue({ id: "", description: "" });
        setSelectedRequestSample(undefined);
    }, [materialReferenceControl.setValue, batchFormControl.setValue,
    waybillControl.setValue, materialReferenceControl.setValue,
    requestTypeFormControl.setValue, setSelectedRequestSample]);



    useEffect(() => {
        const upperRowNode = upperRowRef.current;
        const bottomRowNode = bottomRowRef.current;

        if (upperRowNode && bottomRowNode) {
            let hasWrapOccurred = Array.from(upperRowNode.children, child => child as HTMLElement).some((child, i, arr) => {
                return i > 0 && child.offsetTop > arr[i - 1].offsetTop;
            });

            while (hasWrapOccurred) {
                const lastChild = upperRowNode.lastElementChild;
                setIsFiltersWrapped(true);
                if (lastChild) {
                    upperRowNode.removeChild(lastChild);
                    bottomRowNode.appendChild(lastChild);
                }
                hasWrapOccurred = Array.from(upperRowNode.children, child => child as HTMLElement).some((child, i, arr) => {
                    return i > 0 && child.offsetTop > arr[i - 1].offsetTop;
                });
            }
        }
    }, [windowResize]);



    return (
        <PopupContainer className="correlate-lab-samples">
            <PopupHeader title={translate("REQUESTS.POPUPS.CorrelateSample")} navigateToOnCloseIcon={props.navigateToViewSamples ? "/samples/delivered" : undefined} />
            <PopupContent isLoading={getLabSampleCallIsLoading}>
                {correlateLabSampleCallIsLoading ? <FullScreenLoader /> : null}

                <TitledCardContainer
                    className="lab-sample-details-card" title={translate("REQUESTS.POPUPS.SelectedSample")}>
                    <div className="request-sample-details">

                        <InfoBlock label={translate("SAMPLES.INFOANDFORM.Material")}
                            value={labSampleData?.sample.sapNumber ?
                                `${labSampleData?.sample?.sapNumber} - ${labSampleData?.sample.materialDescription}` || "-"
                                : labSampleData?.sample.othersMaterialDescription || "-"}></InfoBlock>

                        <InfoBlock label={translate("SAMPLES.INFOANDFORM.Batch")}
                            value={labSampleData?.sample.batch || "-"}></InfoBlock>

                        <InfoBlock label={translate("SAMPLES.INFOANDFORM.Observations")}
                            value={labSampleData?.sample.observations || "-"}></InfoBlock>

                        <InfoBlock label={translate("SAMPLES.INFOANDFORM.Waybill")}
                            value={labSampleData?.sample.waybill || "-"}></InfoBlock>

                        {labSampleData?.sample.labOperator && labSampleData?.sample.operatorSection ?
                            <InfoBlock label={translate("COMMON.CreatedBy")}
                                value={labSampleData?.sample.labOperator + " <" + labSampleData?.sample.operatorSection + ">" || "-"} />
                            : null}
                    </div>

                </TitledCardContainer>

                <Spacer mode="vertical" px="24" />

                <div className="samples-to-correlate">
                    <div className="subtitle">
                        {translate("REQUESTS.POPUPS.SelectSampleToCorrelate")}
                    </div>
                    <div className="filters">
                        <div className="upper-row-filters" ref={upperRowRef}>
                            <FormFieldTextInput
                                formControl={requestReferenceFormControl}
                                icon={<SearchIcon />}
                                label={translate("REQUESTS.INFOANDFORM.Reference")}
                                placeholder={"Ex: AR23-0001"}
                                className="search-box"
                            />
                            <FormFieldTextInput
                                formControl={materialReferenceControl}
                                icon={<SearchIcon />}
                                label={translate("REQUESTS.POPUPS.MaterialRef")}
                                placeholder={translate("REQUESTS.INFOANDFORM.MaterialReferencePlaceholder")}
                                className="matRef-box"
                            />

                            <FormFieldTextInput
                                formControl={batchFormControl}
                                icon={<SearchIcon />}
                                label={translate("SAMPLES.INFOANDFORM.Batch")}
                                placeholder={translate("SAMPLES.INFOANDFORM.BatchPlaceholder")}
                                className="waybill-box"
                            />

                            <FormFieldTextInput
                                formControl={waybillControl}
                                icon={<SearchIcon />}
                                label={translate("REQUESTS.POPUPS.Waybill")}
                                placeholder={translate("REQUESTS.INFOANDFORM.WaybillPlaceholder")}
                                className="waybill-box"
                            />
                            <FormFieldSelectSingle
                                label={translate("REQUESTS.INFOANDFORM.RequestType")}
                                placeholder={translate("REQUESTS.INFOANDFORM.TypePlaceholder")}
                                options={requestTypes}
                                labelSelector={labelRequestTypeSelector}
                                idSelector={idRequestTypeSelector}
                                formControl={requestTypeFormControl}
                                isClearSelectionAvailable={true}
                            />

                        </div>
                        <div className="action-button-filters">
                            {isFiltersWrapped ? <IconButton
                                icon={isFiltersPanelExpanded ? <ArrowUp /> : <ArrowDown />}
                                type="tertiary"
                                onClick={() => setIsFiltersPanelExpanded(!isFiltersPanelExpanded)}
                                className="view-more-filters-btn"
                            /> : null}
                            <Tooltip text={translate("COMMON.FORM.CleanFilters")}>
                                <IconButton
                                    icon={<CleanIcon />}
                                    type="secondary"
                                    onClick={handleResetFiltersClicked}
                                    className="reset-filters-btn"
                                />
                            </Tooltip>
                        </div>
                        <div className={"bottom-row-filters" + (isFiltersPanelExpanded ? " expanded" : "")} ref={bottomRowRef}>
                        </div>
                    </div>

                    <ResponsiveDataTable
                        key={tableRenderId}
                        columnDefinitions={columns}
                        items={possibleSamplesToCorrelate || []}
                        totalitems={totalItems || 0}
                        paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [4, 8, 12] }}
                        currentpage={currentpage}
                        isLoading={getPossibleCorrelationsCallIsLoading}
                        onPageAndItemsChanged={handleOnPageAndItemsChanged}
                        showSelectedRowHighlighted
                        onClickRow={(selectedRow) => {
                            if (selectedRow.sample.correlatedLabSampleId)
                                setSelectedRequestSample(undefined);
                            else if (selectedRequestSample?.requestSampleID === selectedRow.sample.id)
                                setSelectedRequestSample(undefined);
                            else setSelectedRequestSample({ requestID: selectedRow.requestID, requestSampleID: selectedRow.sample.id || -1 });
                        }}
                    />
                </div>

                <Spacer mode={"vertical"} px="30" />

                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => props.navigateToViewSamples ? navigate("/samples/delivered") : closePopup(),
                        },
                        {
                            isDisabled: selectedRequestSample?.requestSampleID ? false : true,
                            text: translate("REQUESTS.POPUPS.Correlate"),
                            type: "primary",
                            onClick: handleCorrelateSampleBtnClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer >
    );
}
